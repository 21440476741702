const config = {
  COOKIES_DOMAIN: ".koble.mx",
  NEXT_PUBLIC_IDENTITY_SERVER: "https://staging.identity.koble.mx",
  NEXT_PUBLIC_GRAPHQL: "https://staging.graphql.koble.mx/graphql",
  NEXT_PUBLIC_API: "https://staging.api.koble.mx",
  NEXT_PUBLIC_WS: "wss://staging.ws.koble.mx",
  NEXT_PUBLIC_SSO_FRONTEND: "https://staging.sso.koble.mx",
  NEXT_PUBLIC_USER_STUDENT_FRONTEND: "https://staging.student.koble.mx",
  NEXT_PUBLIC_USER_RECRUITER_FRONTEND: "https://staging.recruiter.koble.mx",
  NEXT_PUBLIC_BACK_OFFICE_FRONTEND: "https://staging.backoffice.koble.mx",
  NEXT_PUBLIC_PUBLIC_FRONTEND: "https://staging.koble.mx",
  NEXT_PUBLIC_RECAPTCHA_SITE_KEY: "6Ldcew8pAAAAANh8tQ62W65rabxMPomrsIxTmucP",
  NEXT_PUBLIC_GOOGLE_CLIENT_ID:
    "781551067901-o2cier3gtbajh79ls4lfn9qhan8mmv8s.apps.googleusercontent.com",
  MIX_PANEL_TOKEN: null,
  FACEBOOK_PIXEL_CODE: 7544537748965734,
};

export default config;
